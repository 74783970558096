// import React, { useState } from "react";
// import { HiLockClosed } from "react-icons/hi";
// import { WiTime10 } from "react-icons/wi";
// import { FaBuildingColumns, FaThumbsUp, FaRegQuestionCircle } from "react-icons/fa";
// import axios from "axios";

// function Header() {
//   const [formData, setFormData] = useState({
//     cashNeeded: "",
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     dateOfBirth: "",
//     address: "",
//     city: "",
//     state: "",
//     zipCode: "",
//     bankName: "",
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null); // Reset error state before submission

//     // Basic validation
//     if (!formData.firstName || !formData.lastName || !formData.email) {
//       setError("Please fill in all required fields.");
//       setLoading(false);
//       return;
//     }

//     try {
//       console.log(formData,"formData")
//       const response = await axios.post("http://localhost:5000/api/loan-application", formData);
//       console.log(response.data.message);
//       setFormData({ // Reset all fields after successful submission
//         cashNeeded: "",
//         firstName: "",
//         lastName: "",
//         email: "",
//         phone: "",
//         dateOfBirth: "",
//         address: "",
//         city: "",
//         state: "",
//         zipCode: "",
//         bankName: "",
//       });
//     } catch (error) {
//       setError(error.response?.data?.error || "An error occurred");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="relative flex z-30 justify-center items-center text-center bg-custom-pattern2 bg-cover w-full">
//       <div className="absolute inset-0 bg-black h-full opacity-70 md:opacity-80 lg:opacity-40 z-0"></div>
//       <div className="z-30 mt-0 md:mt-14 lg:mt-20 max-w-6xl w-full mx-[14px] md:mx-[50px] lg:mx-[20px]">
//         <h1 className="text-custom-navbar-color mt-10 text-[19px] sm:text-[40px] lg:text-[65px] font-[800] font-montserrat uppercase">
//           Personal Loans
//         </h1>
//         <h1 className="text-white text-[19px] md:text-[40px] lg:text-[65px] mt-0 lg:mt-5 font-[800] font-montserrat uppercase">
//           Made Simple and Fast.
//         </h1>
//         <p className="text-white text-[14px] sm:text-[20] md:text-[32px] lg:text-[32px] px-[20px] font-[100] font-montserrat my-2 lg:my-7">
//           Borrow between $100 and $15,000 as soon as tomorrow.
//         </p>
        
//         {/* Form */}
//         <form onSubmit={handleSubmit} className="relative bg-cover rounded-lg px-4 md:px-10 lg:px-20 py-3 z-10">
//           <p className="text-white text-[10px] md:text-[20px] font-[400] font-montserrat my-5">
//             Start our 5 minute online form by telling us how much you need.
//           </p>
          
//           {/* Form Fields */}
//           <div className="lg:flex lg:space-x-6 my-3">
//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Cash Needed</span>
//               <select name="cashNeeded" value={formData.cashNeeded} onChange={handleChange} className="p-2 border-2 border-gray-400 rounded">
//                 <option value="">Select Amount</option>
//                 <option value="$500-$1,000">$500-$1,000</option>
//                 <option value="$1,001-$2,500">$1,001-$2,500</option>
//                 <option value="$2,501-$5,000">$2,501-$5,000</option>
//                 <option value="$5,001-$15,000">$5,001-$15,000</option>
//               </select>
//             </label>

//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">First Name*</span>
//               <input
//                 type="text"
//                 name="firstName"
//                 value={formData.firstName}
//                 onChange={handleChange}
//                 placeholder="First Name"
//                 className="p-2 border-2 border-gray-400 rounded"
//                 required
//               />
//             </label>
            
//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Last Name*</span>
//               <input
//                 type="text"
//                 name="lastName"
//                 value={formData.lastName}
//                 onChange={handleChange}
//                 placeholder="Last Name"
//                 className="p-2 border-2 border-gray-400 rounded"
//                 required
//               />
//             </label>

//             <label className="flex flex-col w-full lg:w-[155%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Email*</span>
//               <input
//                 type="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 placeholder="Email"
//                 className="p-2 border-2 border-gray-400 rounded"
//                 required
//               />
//             </label>
//           </div>

//           <div className="lg:flex lg:space-x-6 my-3">
//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Phone</span>
//               <input
//                 type="tel"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 placeholder="Phone Number"
//                 className="p-2 border-2 border-gray-400 rounded"
//               />
//             </label>

//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Date of Birth</span>
//               <input
//                 type="date"
//                 name="dateOfBirth"
//                 value={formData.dateOfBirth}
//                 onChange={handleChange}
//                 className="p-2 border-2 border-gray-400 rounded"
//               />
//             </label>

//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Address</span>
//               <input
//                 type="text"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//                 placeholder="Address"
//                 className="p-2 border-2 border-gray-400 rounded"
//               />
//             </label>
//           </div>

//           <div className="lg:flex lg:space-x-6 my-3">
//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">City</span>
//               <input
//                 type="text"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleChange}
//                 placeholder="City"
//                 className="p-2 border-2 border-gray-400 rounded"
//               />
//             </label>

//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">State</span>
//               <input
//                 type="text"
//                 name="state"
//                 value={formData.state}
//                 onChange={handleChange}
//                 placeholder="State"
//                 className="p-2 border-2 border-gray-400 rounded"
//               />
//             </label>

//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Zip Code</span>
//               <input
//                 type="text"
//                 name="zipCode"
//                 value={formData.zipCode}
//                 onChange={handleChange}
//                 placeholder="Zip Code"
//                 className="p-2 border-2 border-gray-400 rounded"
//               />
//             </label>
//           </div>

//           <div className="lg:flex lg:space-x-6 my-3">
//             <label className="flex flex-col w-full lg:w-[95%]">
//               <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Bank Name</span>
//               <input
//                 type="text"
//                 name="bankName"
//                 value={formData.bankName}
//                 onChange={handleChange}
//                 placeholder="Bank Name"
//                 className="p-2 border-2 border-gray-400 rounded"
//               />
//             </label>
//           </div>

//           {/* Error Message */}
//           {error && <p className="text-red-500">{error}</p>}

//           {/* Loading Button */}
//           <div className="lg:flex lg:space-x-6 my-3">
//             <label className="flex flex-col w-full lg:w-[120%]">
//               <button
//                 type="submit"
//                 disabled={loading}
//                 className={`bg-custom-button-purpal flex items-center text-white px-10 py-2 border-2 rounded-md ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
//               >
//                 {loading ? "Submitting..." : <><HiLockClosed className="mr-2" /> Get Started</>}
//               </button>
//             </label>
//           </div>

//           <p className="text-white text-[12px] font-[400] my-5 mt-6 font-montserrat">
//             By clicking 'Get Started', you agree to our <span className="text-blue-300">Privacy Policy, Terms</span> and <span className="text-blue-300">E-Consent</span>. * US Residents Only.
//           </p>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default Header;


import React, { useState } from "react";
import { HiLockClosed } from "react-icons/hi";
import { collection, addDoc } from "firebase/firestore"; 
import db from "../../service/firebase"

function Header() {
  const [formData, setFormData] = useState({
    cashNeeded: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    bankName: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null); // Reset error state before submission

    // Basic validation
    if (!formData.firstName || !formData.lastName || !formData.email) {
      setError("Please fill in all required fields.");
      setLoading(false);
      return;
    }

    try {
      // Get a reference to the Firestore collection
      const loanCollectionRef = collection(db, "loan"); // Use collection method correctly

      // Save the form data to Firestore
      await addDoc(loanCollectionRef, formData); // Use addDoc to add the document

      // Reset the form after submission
      setFormData({
        cashNeeded: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dateOfBirth: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        bankName: "",
      });

      // alert("Loan application submitted successfully!"); // Optional success message
    } catch (error) {
      console.log(error);
      setError("An error occurred while saving the form data.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="relative flex z-30 justify-center items-center text-center bg-custom-pattern2 bg-cover w-full">
      <div className="absolute inset-0 bg-black h-full opacity-70 md:opacity-80 lg:opacity-40 z-0"></div>
      <div className="z-30 mt-0 md:mt-14 lg:mt-20 max-w-6xl w-full mx-[14px] md:mx-[50px] lg:mx-[20px]">
        <h1 className="text-custom-navbar-color mt-10 text-[19px] sm:text-[40px] lg:text-[65px] font-[800] font-montserrat uppercase">
          Personal Loans
        </h1>
        <h1 className="text-white text-[19px] md:text-[40px] lg:text-[65px] mt-0 lg:mt-5 font-[800] font-montserrat uppercase">
          Made Simple and Fast.
        </h1>
        <p className="text-white text-[14px] sm:text-[20] md:text-[32px] lg:text-[32px] px-[20px] font-[100] font-montserrat my-2 lg:my-7">
          Borrow between $100 and $15,000 as soon as tomorrow.
        </p>
        
        {/* Form */}
        <form onSubmit={handleSubmit} className="relative bg-cover rounded-lg px-4 md:px-10 lg:px-20 py-3 z-10">
          <p className="text-white text-[10px] md:text-[20px] font-[400] font-montserrat my-5">
            Start our 5 minute online form by telling us how much you need.
          </p>
          
          {/* Form Fields */}
          <div className="lg:flex lg:space-x-6 my-3">
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Cash Needed</span>
              <select name="cashNeeded" value={formData.cashNeeded} onChange={handleChange} className="p-2 border-2 border-gray-400 rounded">
                <option value="">Select Amount</option>
                <option value="$500-$1,000">$500-$1,000</option>
                <option value="$1,001-$2,500">$1,001-$2,500</option>
                <option value="$2,501-$5,000">$2,501-$5,000</option>
                <option value="$5,001-$15,000">$5,001-$15,000</option>
              </select>
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">First Name*</span>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                className="p-2 border-2 border-gray-400 rounded"
                required
              />
            </label>
            
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Last Name*</span>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                className="p-2 border-2 border-gray-400 rounded"
                required
              />
            </label>

            <label className="flex flex-col w-full lg:w-[155%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Email*</span>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="p-2 border-2 border-gray-400 rounded"
                required
              />
            </label>
          </div>

          <div className="lg:flex lg:space-x-6 my-3">
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Phone</span>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Date of Birth</span>
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Address</span>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>
          </div>

          <div className="lg:flex lg:space-x-6 my-3">
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">City</span>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="City"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">State</span>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="State"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Zip Code</span>
              <input
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                placeholder="Zip Code"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>
          </div>

          <div className="lg:flex lg:space-x-6 my-3">
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Bank Name</span>
              <input
                type="text"
                name="bankName"
                value={formData.bankName}
                onChange={handleChange}
                placeholder="Bank Name"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            className="mt-5 bg-custom-navbar-color text-white py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Header;
