// src/service/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration (replace with your own config)
const firebaseConfig = {
    apiKey: "AIzaSyBpSbi1v-ieRXVSGcxokXWr-XGda8P1YnA",
    authDomain: "testproject-15f42.firebaseapp.com",
    projectId: "testproject-15f42",
    storageBucket: "testproject-15f42.firebasestorage.app",
    messagingSenderId: "355621100727",
    appId: "1:355621100727:web:61af571352457a08758132",
    measurementId: "G-PL00SX4JG8"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export default db;
